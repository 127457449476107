<template>
  <dg-select-field
      :defaultValue="defaultValue"
      :editable="true"
      :field_span="24"
      :labelProperty="labelProp"
      :label_span="24"
      :options="options"
      :translateLabel='false'
      :translateLabels="false"
      :updatesOnDefaultValue="false"
      :valueProperty="valueProp"
      @amUpdated="confirmFromUser"
      field_mark=""
      label=""
      name="account_status_id"
      placeholder="Select a Status"
      rules="required"
      v-if="amReady"></dg-select-field>
</template>

<script>
import {mapState} from "vuex";

export default {
  data() {
    return {
      amReady: true,
      defaultValue: this.value
    }
  },
  props: {
    fieldId: {
      required: true,
    },
    updateApi: {
      required: true
    },
    fieldName: {
      required: true
    },
    value: {
      required: true
    },
    valueProp: {
      default: 'value'
    },
    labelProp: {
      default: 'label'
    },
    confirmTitle: {
      default: "Confirm",
    },
    confirmChanges: {
      default: true
    },
    confirmMessage: {
      default: 'Confirm your changes ?'
    },
    options: {
      required: true
    }
  },
  methods: {
    ...$mapActions('Individual-AccountStatus-api', {
      getAccountStatusDropDownList: 'getDropDownList',
    }),
    confirmFromUser(props, val) {
      if (this.confirmChanges) {
        $services['qDialog'].open(this.confirmTitle, this.confirmMessage, () => {
          this.updated(val)
        }, () => {
          this.amReady = false
          $vue.nextTick(() => {
            this.amReady = true
          })
        })
      } else {
        this.updated(val)
      }
      //$store.state['App-App-state'].questionDialogStatus = true
    },
    updated(val) {
      let context = this
      let data = {}
      data[this.fieldName] = val
      let apiParams = {id: this.fieldId, data};

      $store.dispatch(this.updateApi, apiParams).then(async response => {
        this.defaultValue = val
        this.amReady = false
        $services['notify']['success']('Updated')
        $vue.nextTick(() => {
          context.amReady = true
        })
      }).catch(error => {
        this.validData = false
        if (error.response.data.errorMessage) {
          $store.state['App-App-state'].generalDialogStatus = true
          $store.state['App-App-state'].generalDialogMessage = error.response.data.errorMessage
          $store.state['App-App-state'].generalDialogTitle = "Attention"
          this.requestErrorMessage = error.response.data.errorMessage
        } else {
          this.setfieldsErrorMessage(error.response.data);
        }
      }).finally(() => {
      })
    }
  }
}
</script>
